.option-management {
    display: flex;

    .buyer-reorder {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h3 {
            text-align: center;
        }

        .option-actions {
            display: flex;
        }
    }

    .item-action-button-container {
        display: flex;
    }

    .tab-list-container {
        display: flex;
        flex-direction: column;
        min-width: 20vw;

        h3 {
            text-align: center;
        }
        .tab-buttons {
            display: flex;
            justify-content: space-around;
            .MuiButton-root {
                font-weight: bold;
                color: grey;
            }

            .selected {
                color: red;
            }
        }

        .MuiTypography-root {
            color: black;
            font-weight: bold !important;
        }
    }
}

.edit-dialog {
    .MuiPaper-root {
        // min-width: 50vw;
        color: black;
    }

    .MuiDialogTitle-root {
        font-weight: bold;
    }

    .MuiDialogContent-root {
        padding-top: 1vh;
    }

    .edit-content {
        display: flex;
        margin-left: 1vw;
        margin-bottom: 1vh;
        padding-top: 1vh;

        .MuiFormControl-root {
            margin-right: 1vw;

            .MuiInputLabel-root {
                color: black;
                font-weight: bold;
            }

            .MuiInputBase-input {
                font-weight: bold;
            }
        }
    }

    .MuiDialogActions-root {
        .MuiButton-root {
            // color: red;
            font-weight: bold;
        }
    }
}

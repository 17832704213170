.scorecard-scorer {
    padding: 3vh;
    display: flex;

    flex-direction: column;
    height: 75vh;
    overflow-y: scroll;

    .buyer-type-selector {
        display: inline-flex;
        justify-content: center;
        .MuiButton-root {
            background-color: #cc181f;
            color: white;
            font-weight: bold;
            margin-right: 2vw;
        }
    }
    h2 {
        margin-left: 2vw;
        text-align: center;
    }
    .customer-info,
    .cosigner-info {
        margin-bottom: 2vh;
        margin-left: 2vw;
    }

    .back-button {
        justify-content: flex-start;
    }

    .options-section {
        flex-wrap: wrap;

        .MuiFormControl-root {
            // display: flex;
            min-width: 40vw;
            .MuiSelect-select {
                color: black;
                //font-weight: bold;
            }
        }

        .MuiInputLabel-root {
            // color: black;
        }

        .option-form-control {
            margin-left: 2vw;
            margin-bottom: 2vh;
            .MuiInputLabel-root {
                //font-weight: bold;
            }
        }
    }

    .finish-score-button {
        background-color: #cc181f;
        font-weight: bold;
        float: right;
    }

    .finish-score-button:hover {
        background-color: #f0595e;
    }
}

.select-option {
    // color: black !important;
    // font-weight: bold !important;
}

.MuiFormControl-root.MuiTextField-root {
    margin-right: 2vw;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: rgba(110, 110, 110, 0.29);
}

::-webkit-scrollbar-thumb {
    background-color: #888;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

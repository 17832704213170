.user-management {
    height: 60vh;
    padding: 1vmin;
    h4 {
        text-align: center;
        //font-weight: bold;
    }

    .user-data-table {
        color: black;
        // font-weight: bold;

        .MuiDataGrid-columnHeaderTitle {
            font-weight: bold;
        }
    }

   
}

.MuiPaper-root {
    // min-width: 50vw;
    color: black;
}
.center {
    text-align: center;
}
.MuiDialogTitle-root {
    font-weight: bold;
}

.MuiDialogContent-root {
    padding-top: 1vh;
    color: black;
    font-weight: bold;
}

.edit-content {
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 1vh;
    padding-top: 1vh;
    color: black;
    // font-weight: bold;

    .delete-user {
        background-color: #ff333a;
        font-weight: bold;
        float: right;
    }
    
    .switch {
        display: flex;
        align-items: center;
    }

    .MuiFormHelperText-root {
        color: black;
        font-weight: bold;
    }

    .MuiFormControl-root {
        margin-right: 1vw;
        font-weight: bold;
        color: black;

        .MuiInputLabel-root {
            color: black;
            font-weight: bold;
        }

        .MuiInputBase-input {
            font-weight: bold;
            color: black;
        }
    }
}

.MuiDialogActions-root {
    .MuiButton-root {
        // color: red;
        font-weight: bold;
    }

    
}

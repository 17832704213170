.register {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    overflow-y: scroll;

    .register-inputs {
        width: 30%;

        .MuiFormControl-root {
            margin-bottom: 2vh;
        }
    }

    .register-btn {
        background-color: #cc181f;
        font-weight: bold;
        color: white;
    }

    .login {
        margin-top: 2vh;
        color: dodgerblue;
        font-weight: bold;
    }
}

.navigation {
    .header {
        background-color: #222;
        color: white;
        padding-left: 2vw;
        padding-top: 1vh;
        padding-bottom: 1vh;
        padding-right: 1vw;
        display: flex;
        justify-content: space-between;
        height: 10vh;

        .contact-us {
            display: flex;
            flex-direction: column;
            justify-content: center !important;
            p {
                margin: 0;
                font-size: 0.9em;
                color: lightgrey;
                // text-align: right;
            }

            .italic {
                font-style: oblique;
                font-weight: 600;
            }
        }

        .logo-container {
            text-align: center;

            .dealer-title {
                margin-top: -2em;
                font-weight: 400;

                h3 {
                    margin-bottom: 0px;
                }

                p {
                    margin-top: 0.1em;
                    font-size: 0.8em;
                    font-weight: 400;
                }
            }

            svg {
                height: 7vh !important;
                width: initial !important;
                .st1 {
                    fill: white;
                }
            }
        }
        .welcome-container {
            display: flex;
            flex-direction: column;
            padding-right: 1vw;
            justify-content: flex-end;
            .welcome-user {
                align-self: flex-end;
                // padding-right: 2vw;
                h4 {
                    font-weight: 500;
                    margin-top: 0.8em;
                }
            }
        }
    }

    .drawer {
        // font-weight: bold;
    }

    .toolbar {
        justify-content: space-between;
        height: 3vh;
        &.MuiToolbar-root {
            background-color: #cc181f;
        }

        .login-button {
            color: white;
            font-weight: bold;
        }

        .menu-button {
            color: white;

            &.hidden {
                visibility: hidden;
            }
        }
    }
}

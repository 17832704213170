.view-scores {
    overflow-x: hidden;
    overflow-y: hidden;

    .dealer-title {
        margin: 0 auto;
        margin-bottom: 3vh;
    }

    .score-list {
        padding-top: 0px;
    }

    .score-list {
        overflow-y: scroll;
        height: 75vh;
        // position: absolute;
    }

    .car-icon {
        margin-right: 0.2em;
    }

    .score-message {
        color: black;
        font-weight: bold;
        margin-top: 2vh;
        font-size: 1.2em;
    }

    .score-card-viewer {
        flex-grow: 1;
        padding-top: 1vh;
        overflow-y: scroll;
        height: 75vh;
        padding-left: 2vw;
        align-content: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;

        .print-header {
            display: none;
        }

        .print-btn {
            justify-content: flex-start;
            width: min-content;
        }

        .select-vehicle-container {
            display: flex;
            justify-content: flex-end;
        }
        .select-vehicle-btn {
            background-color: #222;
        }

        .scorecard {
            // width: 100%;
            @media all {
                .page-break {
                    display: none;
                }
            }

            @media print {
                html,
                body {
                    height: initial !important;
                    overflow: initial !important;
                    -webkit-print-color-adjust: exact;
                }
            }

            @media print {
                .page-break {
                    margin-top: 1rem;
                    display: block;
                    page-break-before: auto;
                }
            }

            padding-right: 2vw;

            .cosigner-header {
                display: inline-flex;
                width: 100%;
                justify-content: space-between;
            }

            .category-name {
                color: black;
                font-weight: bold;
            }
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: rgba(110, 110, 110, 0.29);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    .print-btn {
        display: none;
    }

    .select-vehicle-container {
        display: none !important;
    }
    .select-vehicle-btn {
        display: none !important;
    }

    .decline-letter-container {
        display: none !important;
    }

    .scorecard {
        color: black !important;
        font-weight: bold;

        h4 {
            text-align: right;
        }
    }

    .print-header {
        display: block;
    }

    .print-logo {
        width: 15em;
    }
}

@page {
    size: auto;
    margin: 20mm;
    @top-left {
        content: url('../../assets/ccs.svg');
    }
}

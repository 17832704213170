.admin {
    height: 75vh;
    overflow-y: scroll;

    .MuiContainer-root {
        max-width: 100%;
        padding: 0;
    }

    .tabs-container {
        display: flex;

        .MuiTab-root {
            color: black;
            font-weight: bold;

            &.Mui-selected {
                color: #cc181f;
            }
        }

        .MuiTabs-indicator {
            background-color: #cc181f;
        }

        .tab-panel {
            display: flex;
            // flex-direction: column;
            flex-grow: 1;
            // justify-content: center;

            &.hidden {
                display: none;
            }
        }
    }
}

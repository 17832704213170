.reset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .reset-failure {
        margin-top: 5vh;
        text-align: center;
    }

    .reset-pwd {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10vh;

        .login-inputs {
            width: 30%;

            .MuiFormControl-root {
                margin-bottom: 2vh;
            }
        }

        .reset-btn {
            background-color: #cc181f;
            font-weight: bold;
            color: white;
        }
    }
    .login-btn {
        margin-top: 2vh;
        color: dodgerblue;
        font-weight: bold;
    }
}

.forgot-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;

    .login-inputs {
        .MuiFormControl-root {
            margin-bottom: 2vh;
        }
    }

    .login-btn {
        background-color: #cc181f;
        font-weight: bold;
        color: white;
    }

    .register {
        margin-top: 2vh;
        color: dodgerblue;
        font-weight: bold;
    }
}

.decline-letter-container {
    display: flex;
    justify-content: flex-end;
    .print-btn {
        width: initial !important;
        background-color: #cc181f;
    }

    .print-btn:hover {
        background-color: #f0595e;
    }
}
.decline-letter {
    display: none;
    .dealer-address {
        line-height: 0.3;
    }

    .reporting-agencies {
        display: flex;
        justify-content: space-evenly;
        .agency {
            line-height: 0.3;
        }
    }
}

@page {
    size: auto;
    margin: 20mm;
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    .decline-letter {
        display: block;
    }

    .print-btn {
        display: none;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}
